import React from 'react';
import ReactDOM from 'react-dom';
import { init as initApm } from '@elastic/apm-rum';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from './store';

import App from './App';

const history = createBrowserHistory();

const apm = initApm({
  serviceName: 'hso-status-react',
  serverUrl: 'https://apm.hso-group.net',
  serviceVersion: process.env.REACT_APP_GIT_SHA,
  environment: process.env.REACT_APP_ENV,
  distributedTracingOrigins: [
    process.env.REACT_APP_API_URL
  ]
});

ReactDOM.render(
  <Provider store={store}>
      <Router history={history}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
  </Provider>,
  document.getElementById('root')
);

export { apm };