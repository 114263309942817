import {CContainer, CRow, CCol, CCard, CBadge, CNavbar, CNavbarBrand, CNavbarNav, CNavItem, CButton, CAlert, CSpinner,
CNavbarText} from '@coreui/react';
import {connect} from "react-redux";
import {Component} from "react";
import {dataFetchError, getComponents, getOpenMessages} from "./actions/data";
import {ENUM_STATUSES} from "./actions/data";
import {Message} from "./components/Message";
import "./scss/index.scss";
import {StatusComponent} from "./components/StatusComponent";
import UpdatingTime from "./components/UpdatingTime";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {MessagesOfStatus} from "./components/MessagesOfStatus";

library.add(fas);

class App extends Component {

  componentDidMount() {
    this.props.dispatch(getOpenMessages());
    this.props.dispatch(getComponents());
    }

  render() {
    return (
      <div>
        <CNavbar expand="lg" color="dark">
          <CContainer>
            <CNavbarBrand href="#">hSo Status</CNavbarBrand>
            <CNavbarNav>
              <CNavbarText>
                Data last updated <UpdatingTime time={this.props.state.data.openMessages.lastUpdate}/>{' '}
                <FontAwesomeIcon icon="sync-alt" spin={this.props.state.data.openMessages.status === ENUM_STATUSES.LOADING} onClick={() => {
                  this.props.dispatch(getOpenMessages());
                  this.props.dispatch(getComponents());
                }}/>
              </CNavbarText>
            </CNavbarNav>
          </CContainer>
        </CNavbar>
        <CContainer>
          <CRow>
            <CCol md={9}>
              <MessagesOfStatus title={"Open"} type={1} status={this.props.state.data.openMessages.status} items={this.props.state.data.openMessages.data.items}/>
              <MessagesOfStatus title={"Future Planned Works"} type={2} status={this.props.state.data.openMessages.status} items={this.props.state.data.openMessages.data.items}/>
              <MessagesOfStatus title={"Recently Closed"} type={0} status={this.props.state.data.openMessages.status} items={this.props.state.data.openMessages.data.items}/>
            </CCol>
            <CCol>
              <h1>Component Status</h1>
              {this.props.state.data.components.status === ENUM_STATUSES.LOADING &&
              <CSpinner color="dark"/>
              }
              {this.props.state.data.components.status === ENUM_STATUSES.ERROR &&
              <CAlert color={'danger'}>
                Could not load data
              </CAlert>
              }
              {this.props.state.data.components.status === ENUM_STATUSES.SUCCESS &&
              <div>
                {this.props.state.data.components.data.map((it) => {
                  return(<StatusComponent {...it} status={this.props.state.data.components.statuses[it.uuid]}/>)
                })}
              </div>
              }
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default connect((state) => ({
  state
}))(App);